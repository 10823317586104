import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginActive from '../view/LoginActive.vue';
Vue.use(VueRouter)
const routes = [
  {
    path:'/',
    name:'proList',
    component:()=>import('../view/message/proList')
  },
  {
    path:'/prodetail/:id/:mid',
    name:'prodetail',
    component:()=>import('../view/message/proDetail')
  },
  {
    path:'/placeorder',
    name:'placeorder',
    component:()=>import('../view/message/placeOrder')
  },
  {
    path: '/login',
    name: 'LoginActive',
    component: LoginActive,
  },
  {
    path: '/orderdetail/:id',
    name: 'orderdetail',
    component: () => import('../view/message/orderdetail')
  },

  {
    path: '/userpage',
    name: 'userpage',
    component: () => import('../view/message/userpage')
  },
  {
    path: '/userorderdetail/:id',
    name: 'userorderdetail',
    component: () => import('../view/message/userorderdetail')
  },
  {
    path: '/merchantpage',
    name: 'merchantpage',
    component: () => import('../view/message/merchantPage'),
    children:[
      {
        path: '/merchantpage/orderlist',
        name: 'orderlist',
        component: () => import('../view/message/orderList')
      },
      {
        path: '/merchantpage/merchantmessage',
        name: 'merchantmessage',
        component: () => import('../view/message/merchantmessage')
      },
      {
        path: '/merchantpage/merchantqrcode',
        name: 'merchantqrcode',
        component: () => import('../view/message/merchantQrCode')
      },
      {
        path: '/merchantpage/merchantPosterImage',
        name: 'merchantPosterImage',
        component: () => import('../view/message/merchantPosterImage')
      },
    ]
  },
  {
    path: '/menuAll',
    name: 'menuAll',
    component: () => import('../view/menuAll')
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router