import axios from "axios";
// import QS from "qs"
import router from '@/router';
// import {Notify} from 'vant'
// import Vue from 'vue';
import {Dialog} from 'vant'
// Vue.prototype.$axios=axios;
// axios.defaults.baseURL="http://ebuy.hillapi.com/ebuy"
axios.defaults.baseURL=process.env.VUE_APP_API
// 请求拦截器
axios.interceptors.request.use((config)=>{
        config.headers['Authorization']=JSON.parse(localStorage.getItem('token'))
        return config;
},function(e){
    return Promise.reject(e);
})
// 响应拦截器
axios.interceptors.response.use((response)=>{
    // const {code,message}=response.data.data
    // if (code === 1) {
    //     MessageBox.alert("会话过期，请重新登录", "登录提示", {
    //         confirmButtonText: "确定",
    //         callback: (action) => {
    //             router.push("/");
    //       },
    //     });
    // }else if(code===3 || code===4 || code===5){
    //     if(message.substring(0,12)=="Failed to co"){
    //         Message.error('请输入数字');
    //     }else{
    //         Message.error(message.substring(0,12));
    //     }
    // }
    if(response.data.data){
        return response.data.data;
    }else if(response.data){
        return response.data
    }else{
        return response
    }

}, function (error) {
    console.log(error.config.url)
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if (error.config.url.indexOf('logout') === -1) {
            Dialog({
              message: '登陆信息已过期,请重新登陆!',
              type: 'error',
            })
          }
        setTimeout(() => {
          localStorage.removeItem('token');
          // 跳转路由
          router.push({
            name: 'LoginActive'
          })
        }, 1000)
    }
    return Promise.reject(error);
});
export function get(url, params,header){
    return new Promise((resolve, reject) =>{
        axios.get(url, {
            params: params
        },header)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err)
        })
    });
}
export function post(url, params,header) {
    return new Promise((resolve, reject) => {
        axios.post(url,params,header)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err)
        })
    });
}
